<template>
  <div>
    <b-card style="background-color:#e9e9e9">
      <b-container fluid>
        <div class="login-form login-signin">
          <div class="text-center mb-5 mb-lg-5">
            <strong style="color:gray;" class="font-size-h3">كود التحقق</strong>
          </div>

          <!--begin::Form-->
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div
              role="alert"
              v-bind:class="{ show: errors.length > 0 }"
              class="alert fade alert-danger"
            >
              <div class="alert-text">
                {{ errors }}
              </div>
            </div>

            <b-form-group
              id="example-input-group-1"
              label-for="example-input-1"
            >
              <span class="text-center mb-5" style="color:gray;">
                لقد أرسلنا رمزا مكونا من ستة أرقام,<br />يرجى كتابته
              </span>

              <!-- Code -->

              <b-form-input
                type="number"
                class="form-control form-control-solid h-auto py-3 px-6"
                id="example-input-2"
                placeholder="XXX-XXX"
                name="example-input-1"
                v-model="$v.form.code.$model"
                :state="validateState('code')"
                aria-describedby="input-2-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input-2-live-feedback">
                الكود لا يمكن أن يكون أكثر أو أقل من 6 أرقام
              </b-form-invalid-feedback>
              <!-- Code -->
            </b-form-group>

            <!--begin::Action-->
            <div
              class="form-group d-flex flex-wrap justify-content-between align-items-center"
            >
              <button
                ref="kt_login_signin_submit"
                style="background-color:gray;color:white;"
                class="btn font-weight-bold h-auto py-2 px-24"
              >
                التحقق
              </button>
            </div>

            <div
              class="text-center mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
            >
              <span class="font-weight-bold font-size-3 text-dark-60">
                <a
                  style="color:gray;"
                  class="font-weight-bold font-size-3 ml-5 mr-5"
                  @click="resendCode"
                >
                  <u>إعادة إرسال الرمز</u>
                </a>
              </span>
            </div>

            <div
              class="text-center mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
            >
              <span class="font-weight-bold font-size-3 text-dark-60">
                <a
                  style="color:gray;"
                  class="font-weight-bold font-size-3 ml-5 mr-5"
                  @click="$router.push('/login')"
                >
                  <u>تسجيل الدخول</u>
                </a>
              </span>
            </div>
            <div
              class="text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
            >
              <span class="font-weight-bold font-size-3 text-dark-60"> </span>
            </div>
            <!--end::Action-->
          </b-form>
          <!--end::Form-->
        </div>
      </b-container>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import {
  VERIFY_CODE,
  VERIFY_FORGET_CODE,
  GET_USER
} from "@/core/services/store/auth.module";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "VerifyCode",
  data() {
    return {
      prevPage: "",
      form: {
        code: ""
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == null || from.name != "login") {
      next("login");
    } else {
      next(vm => {
        vm.prevPage = from.name;
      });
    }
    next();
  },
  validations: {
    form: {
      code: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty } = this.$v.form[name];
      return $dirty
        ? this.$v.form[name].$model.length != 6
          ? false
          : true
        : null;
    },
    resetForm() {
      this.form = {
        code: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    resendCode() {},
    onSubmit() {
      const code = this.$v.form.code.$model;
      if (code.length != 6) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      if (this.$route.name == "verifyLoginCode") {
        setTimeout(() => {
          this.$store.dispatch(VERIFY_CODE, code).then(() => {
            this.$store.dispatch(GET_USER).then(() => {
                  const idUser =JSON.parse(window.localStorage.getItem("id"));
           
            // id=id.toString();
               this.$socket
          .invoke("Done", idUser)
         
              this.$router.push({ name: "dashboard" });
            });
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      } else {
        setTimeout(() => {
          this.$store
            .dispatch(VERIFY_FORGET_CODE, code)
            .then(() => this.$router.push({ name: "forget_password" }));
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
